import { Box, darkColors, Flex } from '@pancakeswap/uikit'
import styled from 'styled-components'

export const HERO_TOP_BG = 'linear-gradient(139.73deg, #E6FDFF 0%, #F3EFFF 100%)'
export const HERO_BOTTOM_BG = 'linear-gradient(180deg, #FFFFFF 22%, #D7CAEC 100%)'
export const HERO_SVG_TOP =
  'linear-gradient(180deg, rgba(255, 255, 255, 0.48) 24.67%, rgba(255, 255, 255, 0.35) 67.34%, #FFFFFF 100%);'
export const HERO_SVG_BOTTOM = '#D8CBED'
export const HoundSwap = styled.div`
  width: 80%;
  text-align: center;
  margin: 20px auto;
  .title {
    color: var(--紫色, rgba(118, 69, 217, 1));
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }
  .content {
    text-align: center;
    font-family: Outfit;
    color: var(--黑色, rgba(40, 13, 95, 1));
    font-family: Outfit;
    font-weight: 400;
    margin-top: 24px;
    font-size: 17px;
    line-height: 150%;
  }
`

export const Share = styled.div`
  padding: 86px 16px;
  margin-top: 133px;
  // height: 100vh;
  position: relative;
  background: url(https://upload.ggg.dog/1724066668/1.png);
  font-size: 14px;
  font-family: 'PingFang SC';
  background-size: cover;
  .Img {
    position: absolute;
    left: calc(50% - 147px);
    top: -56px;
  }
  .Img1 {
    position: absolute;
    left: calc(50% - 114px);
    bottom: 25px;
  }
  .Shareholder {
    margin-bottom: 16px;
    position: relative;
    padding: 32px 24px;
    z-index: 111;
    // margin-top: 86px;
    border-radius: 40px;
    border: 1px solid var(--描边, rgba(231, 227, 235, 1));
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.9);
    .button {
      padding: 0 26px;
      line-height: 46px;
      margin-top: 16px;
      color: #fff;
      text-align: Center;
      border-radius: 15px;
      background: var(--青色, rgba(29, 85, 228, 1));
      width: 100%;
    }
    span {
      padding: 4px 12px;
      border-radius: 29px;
      color: #fff;
      background: var(--黄色, rgba(255, 169, 63, 1));
      font-weight: 600;
    }
    p {
      margin-top: 16px;
      color: var(--灰色, rgba(111, 99, 158, 1));
      line-height: 20px;
    }
    .Role {
      margin: 14px auto;
      width: 236px;
      height: 311px;
    }
  }
`
export const StyledFooter = styled(Flex)`
  background: rgba(17, 4, 43, 1);
`
export const Transaction = styled(Flex)`
  width: 90px;
  line-height: 32px;
  justify-content: center;
  margin-top: 16px;
  color: rgb(255, 169, 63);
  border: 1px solid;
  border-radius: 10px;
`

export const StyledList = styled.ul`
  list-style: none;
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0px;
  }
`

export const StyledListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;

  &:first-child {
    color: ${darkColors.secondary};
    font-weight: 600;
    text-transform: uppercase;
  }
`

export const StyledIconMobileContainer = styled(Box)`
  margin-bottom: 24px;
`
export const Join = styled.div`
  font-size: 14px;
  background: url(https://upload.ggg.dog/1724062163/1.png);
  background-size: cover;
  padding-top: 17px;
  padding-bottom: 100px;
  .contain {
    width: 343px;
    border-radius: 40px;
    border: 1px solid var(--灰色, rgba(231, 227, 235, 1));
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.8);
    height: 484px;
    margin: 0 auto;
    ul {
      display: flex;
      justify-content: space-between;
      margin-top: 38px;
      padding: 0 26px;
      li {
        color: var(--灰色, rgba(111, 99, 158, 1));
        font-family: Outfit;
        font-size: 20px;
        text-align: center;
        list-style: none;
        p {
          width: 40px;
          line-height: 32px;
          border-radius: 5px;
          background: var(--描边, rgba(231, 227, 235, 1));
          color: var(--紫色, rgba(118, 69, 217, 1));
          font-weight: 700;
          font-size: 17px;
        }
        span {
          font-family: 'PingFang SC';
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
        }
      }
    }
    .swap {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      padding: 0 26px;
      div {
        width: 47%;
        padding: 12px 16px;
        border-radius: 16px;
        background: var(--背景, rgba(236, 242, 243, 1));
        line-height: 18px;
        font-weight: 700;
        color: var(--黑色, rgba(40, 13, 95, 1));
        display: flex;
        justify-content: space-between;
        .usdt {
          color: var(--黄色, rgba(255, 169, 63, 1));
          font-family: 'PingFang SC';
          font-weight: 400;
          font-size: 12px;
        }
        .hound {
          color: var(--青色, rgba(29, 85, 228, 1));
          font-family: 'PingFang SC';
          font-weight: 400;
          font-size: 12px;
        }
      }
    }
    .invite {
      margin-top: 24px;
      padding: 0 26px;
      p {
        color: var(--黑色, rgba(40, 13, 95, 1));
        font-weight: 400;
        font-size: 12px;
      }
      input {
        padding: 13px 16px;
        border-radius: 38px;
        border: 1px solid var(--描边, rgba(231, 227, 235, 1));
        box-sizing: border-box;
        margin-top: 8px;
        width: 100%;
        outline: none;
        color: var(--黑色, rgba(40, 13, 95, 1));
        font-family: Outfit;
        font-weight: 400;
        font-size: 14px;
      }
    }
    .button {
      padding: 0 26px;
      margin-top: 16px;
      text-align: Center;
      button {
        color: #fff;
        line-height: 46px;
        border: none;
        border-radius: 15px;
        background: var(--青色, rgba(29, 85, 228, 1));
        width: 100%;
      }
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
    border-radius: 40px 40px 0 0;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`
