import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useEffect, useState } from 'react'
import { Page } from './ProgressBar'

const ProgressBar = ({ current, total, text, onDataHandle, isAir }) => {
  const progressPercentage = (current / total) * 100
  console.log(text, 'text111111111111111')
  const MintHandle = () => {
    onDataHandle()
  }
  const { chainId, account } = useActiveWeb3React()

  const [content, setContent] = useState('')

  useEffect(() => {
    if (text) {
      setContent('Mint')
      if (isAir) {
        setContent('已铸造')
      }
    }
  }, [account, text, isAir])
  console.log(isAir, 'isAir')
  return (
    <Page>
      <div className="progress-container">
        <div className="progress-bar">
          <div
            className="progress-fill"
            style={{ width: `${progressPercentage > 100 ? 100 : progressPercentage}%` }}
            onClick={isAir ? undefined : MintHandle}
          >
            {content}
          </div>
        </div>
        <div className="progress-text">{`${progressPercentage > 100 ? 100 : progressPercentage}%`}</div>
      </div>
    </Page>
  )
}

export default ProgressBar
