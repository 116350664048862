import styled from 'styled-components'

export const Instr = styled.div`
  z-index:9999;
  position:relative;
  img {
    width: 100%;
  }
  .button {
      padding: 0 26px;
      line-height: 46px;
      margin-top: 16px;
      color: #fff;
      text-align: Center; 
      border-radius: 15px;
      background: var(--青色, rgba(29, 85, 228, 1));
      width: 48%;
  }    
  .title {
    text-align: center;
    color: var(--紫色, rgba(118, 69, 217, 1));
    font-family: Outfit;
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    margin-top: 80px;
    span {
      color: var(--黑色, rgba(40, 13, 95, 1));
      font-family: 'PingFang SC';
      font-weight: 600;
    }
  }
  .text {
    padding: 0 24px 32px;
    font-family: 'PingFang SC';
    p {
      margin: 14px 0;
      color: var(--黑色, rgba(40, 13, 95, 1));
      font-weight: 600;
      font-size: 17px;
      line-height: 24px;
    }
    ul li {
      list-style: none;
      color: var(--灰色, rgba(111, 99, 158, 1));
      font-size: 13px;
      line-height: 160%;
    }
    .btn {
      width:100%;
      margin: 24px 0;
      text-align: center;
      color: #fff;
      border-radius: 15px;
      background: var(--青色, rgba(29, 85, 228, 1));
      padding: 13px 16px;
    }
    span {
      color: var(--黄色, rgba(255, 169, 63, 1));
      font-family: 'PingFang SC';
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
    .Bar {
      margin-top: 16px;
    }
  }
  .content {
    margin-top: 36px;
    border-radius: 40px;
    background: linear-gradient(168.83deg, rgba(230, 253, 255, 1) 3.27%, rgba(243, 239, 255, 1) 104.79%);
  }
`
