import styled from 'styled-components'

export const Page = styled.div`
  .progress-container {
    display: flex;
    align-items: center;
    position: relative;
    height: 38px;
    background-color: #6a35d1;
    border-radius: 19px;
    padding: 5px;
    border: 3px solid #ffa300;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
  }

  .progress-bar {
    flex-grow: 1;
    height: 100%;
    background-color: #6a35d1;
    border-radius: 11px;
    overflow: hidden;
    position: relative;
  }

  .progress-fill {
    height: 100%;
    line-height: 22px;
    font-size: 20px;
    font-weight: 700;
    color: #6a35d1;
    text-align: center;
    background-color: #ffa300;
    background-image: repeating-linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.5) 0,
      rgba(255, 255, 255, 0.5) 5px,
      transparent 5px,
      transparent 10px
    );
    border-radius: 10px;
    transition: width 0.3s ease;
  }

  .progress-text {
    display: flex;
    position: absolute;
    right: 10px;
    color: rgba(255, 255, 255, 0.7);
    font-family: Outfit;
    font-size: 17px;
    line-height: 21px;
    .current {
      margin-right: 5px;
      color: var(--白色, rgba(255, 255, 255, 1));
    }
    .total {
      color: rgba(167, 136, 231, 1);
    }
  }
`
