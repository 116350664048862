import { useTranslation } from '@pancakeswap/localization'
import { Content, Features } from './style'

function App() {
  const { t } = useTranslation()

  const options = [
    {
      id: 1,
      img: 'https://upload.ggg.dog/1724124547/1.png',
      title: t('Best price & lowest fee'),
      content: t(
        'We use advanced aggregation technology to screen out the best transaction prices for you, while ensuring that transaction fees remain at the lowest levels in the market. Every transaction can save you more',
      ),
    },
    {
      id: 2,
      img: 'https://upload.ggg.dog/1724124563/1.png',
      title: t('Security'),
      content: t(
        'Safety is our top priority. Thanks to industry-leading security measures and ongoing technology updates, every transaction you make is protected. From smart contract audits to anti-phishing technology, we protect your assets with expertise',
      ),
    },
    {
      id: 3,
      img: 'https://upload.ggg.dog/1724124578/1.png',
      title: t('community shared governance'),
      content: t(
        'At HoundSwap, every user is an important member of our community. We encourage users to participate in the decision-making process of the platform, jointly promote our development, and achieve a community-driven governance model.',
      ),
    },
    {
      id: 4,
      img: 'https://upload.ggg.dog/1724124591/1.png',
      title: t('Easy to use'),
      content: t(
        'Whether you are new to DeFi or an experienced trader, HoundSwap’s simple interface makes it easy for you to get started. Easily manage your digital assets in just a few steps, making complex transactions simple and intuitive.',
      ),
    },
  ]
  return (
    <>
      <Features>
        <Content>
          <div className="title">
            <span style={{ fontFamily: 'Outfit' }}>Hound Swap</span>
            <span className="feature">{t('Features')}</span>
          </div>
          <div className="content">
            {options.map((item) => (
              <div key={item.id}>
                <div>
                  <img src={item.img} alt="" />
                </div>
                <p>{item.title}</p>
                <div className="instruction">{item.content}</div>
              </div>
            ))}
          </div>
        </Content>
      </Features>
    </>
  )
}
export default App
