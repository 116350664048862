import { useTranslation } from '@pancakeswap/localization'
import { useEffect, useState } from 'react'
// import { useWeb3LibraryContext } from '@pancakeswap/wagmi'
import { Box, LogoWithTextIcon, PageSection, useMatchBreakpoints } from '@pancakeswap/uikit'
import { Flex, message } from 'antd'
import { Ido } from 'config/abi/Ido'
import { IDOContractAddress } from 'config/Contract'
import copy from 'copy-to-clipboard'
import { ethers } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useTheme from 'hooks/useTheme'
import { styled } from 'styled-components'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Instruction, StyledSocialLinks, StyledToolsContainer } from '@pancakeswap/uikit/components/Footer/styles'
import { Autoplay, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import Features from './components/Features'
import HoundNFT from './components/HoundNFT'
import { HoundSwap, Join, Share, StyledFooter, StyledIconMobileContainer, Transaction } from './pageSectionStyles'

const StyledHeroSection = styled(PageSection)`
  padding-top: 16px;

  ${({ theme }) => theme.mediaQueries.md} {
    padding-top: 48px;
  }
`

const Home: React.FC<React.PropsWithChildren> = () => {
  const { theme } = useTheme()
  const HomeSectionContainerStyles = { margin: '0', width: '100%', maxWidth: '968px', padding: '0px 16px' }
  const { t } = useTranslation()
  const { isMobile } = useMatchBreakpoints()
  const { chainId, account } = useActiveWeb3React()
  const [Link, setLink] = useState('')
  const copyMyLinkHandler = () => {
    copy(Link)
    message.success('复制成功')
  }
  console.log(account, 'account')
  const currentUrl = window.location.href
  const urlObj = new URL(currentUrl)

  const inviteAddress = urlObj.searchParams.get('invite')

  console.log(inviteAddress, 'inviteAddress ')
  const BindHandle = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        console.log(111)
        const provider = new ethers.providers.Web3Provider(window.ethereum as any)
        const signer = provider.getSigner()
        const Contract = new ethers.Contract(IDOContractAddress, Ido, signer)
        console.log(Contract, 'Contract')
        const res = await Contract.bind(inviteAddress)
        console.log(res, 'res')
      } catch (error) {
        console.log(error, 'error')
      }
    }
  }

  useEffect(() => {
    if (account) {
      BindHandle()
    }
  }, [account])
  useEffect(() => {
    if (window) {
      if (account) {
        const str = `${window.location.origin}/?invite=${account}`
        setLink(str)
      } else {
        setLink('')
      }
    }
  }, [account])

  const [firstValue, setFirstValue] = useState(0)
  const [secondValue, setSecondValue] = useState(false)

  const handleValuesChange = (newFirstValue, newSecondValue) => {
    setFirstValue(newFirstValue)
    setSecondValue(newSecondValue)
  }
  useEffect(() => {
    if (!secondValue) {
      setFirstValue(0)
    }
  })
  function getCountdown(targetDate) {
    const now = new Date()
    const target = new Date(targetDate)
    const timeDiff = Number(target) - Number(now)

    if (timeDiff <= 0) {
      return ['00', '00', '00', '00']
    }

    const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
    const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000)

    return [days, hours, minutes, seconds]
  }

  // 设置目标时间
  const targetDate = '2024-09-18T00:00:00' // 示例时间
  const [Time, setTime] = useState([])
  useEffect(() => {
    const intervalId = setInterval(() => {
      const newTime = getCountdown(targetDate)
      // console.log(new Date('2024-09-19T00:00:00').getTime(), new Date().getTime(),'data')
      if (new Date(targetDate).getTime() !== new Date().getTime()) {
        setTime(newTime as any)
      }
    }, 1000)

    return () => clearInterval(intervalId)
  }, [targetDate]) // 依赖时间和目标日期

  return (
    <Box style={{ width: isMobile ? '100vw' : 'calc(100vw - 8px)', overflow: 'hidden', boxSizing: 'border-box' }}>
      <style jsx global>
        {`
          #home-1 .page-bg {
            background: linear-gradient(139.73deg, #e6fdff 0%, #f3efff 100%);
          }
          [data-theme='dark'] #home-1 .page-bg {
            background: radial-gradient(103.12% 50% at 50% 50%, #21193a 0%, #191326 100%);
          }
          #home-2 .page-bg {
            background: linear-gradient(180deg, #ffffff 22%, #d7caec 100%);
          }
          [data-theme='dark'] #home-2 .page-bg {
            background: linear-gradient(180deg, #09070c 22%, #201335 100%);
          }
          #home-3 .page-bg {
            background: linear-gradient(180deg, #6fb6f1 0%, #eaf2f6 100%);
          }
          [data-theme='dark'] #home-3 .page-bg {
            background: linear-gradient(180deg, #0b4576 0%, #091115 100%);
          }
          #home-4 .inner-wedge svg {
            fill: #d8cbed;
          }
          [data-theme='dark'] #home-4 .inner-wedge svg {
            fill: #201335;
          }

          #bottom-wedge4-2 svg {
            fill: #72b8f2;
          }
          [data-theme='dark'] #bottom-wedge4-2 svg {
            fill: #0b4576;
          }
        `}
      </style>
      <Join>
        <div className="contain">
          <div className="Img">
            <Swiper
              spaceBetween={30}
              loop
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              modules={[Autoplay, Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <img src="https://upload.ggg.dog/1725601425/1.png" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="https://upload.ggg.dog/1725601446/1.png" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="https://upload.ggg.dog/1725605835/1.png" alt="" />
              </SwiperSlide>
              <SwiperSlide>
                <img src="https://upload.ggg.dog/1725605880/1.png" alt="" />
              </SwiperSlide>
            </Swiper>
          </div>
          <ul>
            <li>
              <p>{Time[0] === 0 ? '00' : Time[0]}</p>
              <span>D</span>
            </li>
            <li>:</li>
            <li>
              <p>{Time[1] === 0 ? '00' : Time[1]}</p>
              <span>H</span>
            </li>
            <li>:</li>

            <li>
              <p>{Time[2] === 0 ? '00' : Time[2]}</p>
              <span>M</span>
            </li>
            <li>:</li>
            <li>
              <p>{Time[3] === 0 ? '00' : Time[3]}</p>
              <span>S</span>
            </li>
          </ul>
          <div className="swap">
            <div>
              <span>{firstValue * 10}</span>
              <span className="usdt">USDT</span>
            </div>
            <div>
              <span>{secondValue ? 10000 : 0}</span>
              <span className="hound">HOUND</span>
            </div>
          </div>
          <div className="invite">
            <p>{t('Invitation link')}</p>
            <input type="text" value={Link} />
          </div>
          <div className="button">
            <button type="button" onClick={copyMyLinkHandler}>
              {t('Copy')}
            </button>
          </div>
        </div>
      </Join>
      <HoundSwap>
        <div className="title">HoundSwap</div>
        <div className="content">
          {t(
            'HoundSwap is an innovative decentralized finance (DeFi) platform designed to offer users a comprehensive, efficient, and secure solution for blockchain asset exchange.',
          )}
        </div>
      </HoundSwap>
      <Share>
        <div className="Img">
          <img src="https://upload.ggg.dog/1724068456/1.png" alt="" />
        </div>

        <HoundNFT onValuesChange={handleValuesChange} bind={BindHandle} />

        <div className="Img1">
          <img src="https://upload.ggg.dog/1724120089/1.png" alt="" />
        </div>
      </Share>
      <Features />
      <StyledFooter
        data-theme="dark"
        p={['40px 16px', null, '56px 40px 32px 40px']}
        position="relative"
        justifyContent="center"
      >
        <Flex style={{ flexDirection: 'column' }}>
          <StyledIconMobileContainer display={['block', null, 'none']}>
            <LogoWithTextIcon width="130px" />
          </StyledIconMobileContainer>
          {/* <Flex
          order={[2, null, 1]}
          flexDirection={["column", "column", "column", "column", "row", "row"]}
          justifyContent="space-between"
          alignItems="flex-start"
          mb={["42px", null, "36px"]}
        >
          {items?.map((item) => (
            <StyledList key={item.label}>
              <StyledListItem>{item.label}</StyledListItem>
              {item.items?.map(({ label, href, isHighlighted = false }) => (
                <StyledListItem key={label}>
                  {href ? (
                    <Link
                      data-theme="dark"
                      href={href}
                      target="_blank"
                      rel="noreferrer noopener"
                      color={isHighlighted ? vars.colors.warning : "text"}
                      bold={false}
                    >
                      {label}
                    </Link>
                  ) : (
                    <StyledText>{label}</StyledText>
                  )}
                </StyledListItem>
              ))}
            </StyledList>
          ))}
          <Box display={["none", null, "block"]}>{isXl ? <LogoIcon /> : <LogoWithTextIcon width="160px" />}</Box>
        </Flex> */}

          <StyledToolsContainer color="#FFF" flexDirection={['column', null, 'row']} justifyContent="space-between">
            {/* <Flex order={[2, null, 1]} alignItems="center">
            {isMounted && <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />}
            <LangSelector
              currentLang={currentLang}
              langs={langs}
              setLang={setLang}
              color="textSubtle"
              dropdownPosition="top-right"
            />
          </Flex>
          <Flex order={[1, null, 2]} mb={["24px", null, "0"]} justifyContent="space-between" alignItems="center">
            <Box mr="20px">
              <CakePrice chainId={chainId} cakePriceUsd={cakePriceUsd} color="textSubtle" />
            </Box>
            <Button
              data-theme="dark"
              as="a"
              href={buyCakeLink}
              target="_blank"
              scale="sm"
              endIcon={<ArrowForwardIcon color="backgroundAlt" />}
            >
              {buyCakeLabel}
            </Button>
          </Flex> */}
            {t(
              'Join HoundSwap now and enjoy a faster, safer and more convenient cross-chain trading experience. Here, every transaction you make is full of infinite possibilities!',
            )}
            <Transaction>{t('go trade')}</Transaction>
            <StyledSocialLinks pt={['24px', null, '24px']} pb={['24px', null, '32px']} />
            <Instruction>
              <p>Hound Swap @2024</p>
              <span>{t('Based on Binance Smart Chain')}</span>
            </Instruction>
          </StyledToolsContainer>
        </Flex>
      </StyledFooter>
    </Box>
  )
}

export default Home
