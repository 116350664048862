import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.665 3.71731L2.935 10.5543C1.725 11.0403 1.732 11.7153 2.713 12.0163L7.265 13.4363L17.797 6.79131C18.295 6.48831 18.75 6.65131 18.376 6.98331L9.843 14.6843H9.841L9.843 14.6853L9.529 19.3773C9.989 19.3773 10.192 19.1663 10.45 18.9173L12.661 16.7673L17.26 20.1643C18.108 20.6313 18.717 20.3913 18.928 19.3793L21.947 5.15131C22.256 3.91231 21.474 3.35131 20.665 3.71731Z" fill="#6F639E" />
    </svg>

  );
};

export default Icon;
