import { useTranslation } from '@pancakeswap/localization'
import { message } from 'antd'
import { USDT } from 'config/abi/ERC20_ABI'
import { Ido } from 'config/abi/Ido'
import { IDOContractAddress, USDTContractAddress } from 'config/Contract'
import { ethers } from 'ethers'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useEffect, useState } from 'react'
import ProgressBar from '../ProgressBar/index'
import { Instr } from './style'

function App({ onValuesChange, bind }) {
  const { chainId, account } = useActiveWeb3React()
  console.log(account, 'account')

  // 进度条长度
  const [barLength1, setBarLength1] = useState('')
  const [barLength2, setBarLength2] = useState('')

  // 是否显示铸造
  const [isMint1, setIsMint1] = useState(false)
  const [isMint2, setIsMint2] = useState(false)

  // 是否显示已铸造
  const [isBoss, setIsBoss] = useState(false)
  const [isAir, setIsAir] = useState(false)

  // 是否显示已认购
  const [Boss, setBoss] = useState(false)
  const [Air, setAir] = useState(false)
  const [parentAddr, setParentAddr] = useState('')

  let provider
  let signer
  let Contract
  let IdoContract
  if (typeof window.ethereum !== 'undefined') {
    provider = new ethers.providers.Web3Provider(window.ethereum as any)
    signer = provider.getSigner()
    Contract = new ethers.Contract(USDTContractAddress, USDT, signer)
    IdoContract = new ethers.Contract(IDOContractAddress, Ido, signer)
  }
  // eslint-disable-next-line consistent-return
  const shareholderBuy = async () => {
    if (typeof window.ethereum !== 'undefined') {
      progressBar1()
      console.log(parentAddr, 'parentAddr')
      if (parentAddr === '0x0000000000000000000000000000000000000000') {
        message.error('请先绑定邀请关系')
        bind()
        return
      }

      if (buy1 !== '已认购') {
        try {
          const amount = 1000000n * 10n ** 18n

          await (await Contract.approve(IDOContractAddress, amount)).wait()

          const res = await IdoContract.bossIdo()

          console.log(res, 'res')
          message.success('支付成功')
          setBuy1('已认购')
        } catch (error) {
          message.error('支付失败')
          console.log(error, 'error')
        }
      } else {
        message.error('已认购')
      }
    }
  }

  const recommendationBuy = async () => {
    if (typeof window.ethereum !== 'undefined') {
      if (buy2 !== '已认购') {
        try {
          const amount = 1000000n * 10n ** 18n
          const res1 = await IdoContract.getUserInfo(account)
          console.log(res1)
          const res2 = await IdoContract.getUserInfo(res1.partnerAddr)
          if (!res2.isAir && !res2.isBoss) {
            message.error('上级地址未认购推荐官')
            return
          }

          await (await Contract.approve(IDOContractAddress, amount)).wait()

          const res = await IdoContract.ido()

          console.log(res, 'res')
          message.success('支付成功')
          setBuy2('已认购')
        } catch (error) {
          message.error('支付失败')
          console.log(error, 'error')
        }
      } else {
        message.error('已认购')
      }
    }
  }
  const progressBar1 = async () => {
    try {
      const res = await IdoContract.getUserInfo(account)
      console.log(res, 'res')
      setParentAddr(res.partnerAddr)
      setBarLength1(res.bossInviters.length)
      // setHound(res.isAir)
      console.log(res.bossInviters.length, 'res.bossInviters.length')
      setBoss(res.isBoss)

      if (res.bossInviters.length >= 3) {
        setIsMint1(true)
        setIsBoss(res.isMintNFT)
      } else {
        setIsMint1(false)
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }
  const progressBar2 = async () => {
    try {
      const res = await IdoContract.getUserInfo(account)
      setBarLength2(res.inviters.length)
      console.log(res.inviters.length, 'res2.inviters.length')
      console.log(res.isMintStardNFT, 'res.isMintStardNFT')
      setAir(res.isAir)

      if (res.inviters.length >= 2) {
        setIsMint2(true)
        setIsAir(res.isMintStardNFT)
      } else {
        setIsMint2(false)
      }
    } catch (error) {
      console.log(error, 'error')
    }
  }
  console.log(isMint2, 'IsMint2')
  useEffect(() => {
    if (typeof window.ethereum !== 'undefined') {
      progressBar1()
      progressBar2()
    }
  })
  const shareholderMint = async () => {
    if (!isMint1) {
      return
    }

    try {
      const res = await IdoContract.claimBossNFT()
      console.log(res, 'res')
      message.success('铸造成功')
    } catch (error) {
      console.log(error)
      message.error('未达到铸造条件')
    }
  }
  const recommendationMint = async () => {
    console.log(isMint2)
    if (!isMint2) {
      return
    }

    if (Air) {
      try {
        const res = await IdoContract.claimNFT()

        console.log(res, 'res')
        message.success('铸造成功')
      } catch (error) {
        message.error('未达到铸造条件')
        console.log(error, 'error')
      }
    } else {
      message.error('请先认购推荐官')
    }
  }

  useEffect(() => {
    onValuesChange(barLength2, Air)
  })
  const [buy1, setBuy1] = useState('300U')
  const [buy2, setBuy2] = useState('100U')
  useEffect(() => {
    setBuy1('300U')
    if (Boss) {
      setBuy1('已认购')
    }
  }, [Boss])
  useEffect(() => {
    setBuy2('100U')
    if (Air) {
      setBuy2('已认购')
    }
  }, [Air])
  const { t } = useTranslation()

  return (
    <Instr>
      <div className="title">
        {t('Hound NFT')}
        <span>{t('Introduce')}</span>
      </div>
      <div className="content">
        <div>
          <img src="https://upload.ggg.dog/1725366330/1.png" alt="" />
        </div>
        <div className="text">
          <p>{t('Shareholder NFT Description')}:</p>
          <ul>
            <li>
              {t(
                "Shareholder NFT is a symbol of shareholder identity on the Hound platform. Shareholders can obtain Mint permissions by completing tasks on the blockchain, and holders can permanently enjoy the platform's five major benefits.",
              )}
            </li>
            {/* <span style={{ color: 'var(--紫色, rgba(118, 69, 217, 1))' }}> */}

            {/* </span> */}
          </ul>
          {/* <p>{t('Holders of NFT enjoy 5 major rights and interests')}</p>
          <ul>
            <li>{t('Get an additional 300 USDT dividend reward')}</li>
            <li>{t('Receive an additional 20,000 Hound Coins as a reward')}</li>
            <li>{t('Get BNB dividends of 6% of the entire network’s transaction fees')}</li>
            <li>{t('Use the trading copy mining pool to sell 2% WBNB dividends')}</li>
            <li>{t('Get WBNB dividends of 5% of the game ecological gold coin exchange fee.')}</li>
          </ul> */}
          <div className="Bar">
            <ProgressBar current={barLength1} total={10} text={isMint1} onDataHandle={shareholderMint} isAir={isBoss} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="btn" onClick={shareholderBuy}>
              {buy1}
            </div>
          </div>
        </div>
      </div>

      {/* <div className="content">
        <div>
          <img src="https://upload.ggg.dog/1725366365/1.png" alt="" />
        </div>
        <div className="text">
          {/* <p>{t('Holding Recommended Officer NFT enjoys 2 major benefits')}</p>
          <ul>
            <li>{t('You can enjoy 3% WBNB dividend from sales in the trading and copying mining pool.')}</li>
            <li>{t('Get 3% of the game ecological gold coin exchange fee as WBNB dividends')}</li>
          </ul>
          <p>{t('Recommendation Officer NFT Description')}:</p>
          <ul>
            <li>
              {t(
                'Recommendation NFT is a symbol of the identity of the recommendation officer on the Hound platform. By completing invitation tasks through the community, the recommendation officer can obtain Mint privileges, and the holder can permanently enjoy two major benefits of the platform.',
              )}
            </li>
          </ul>

          <div className="Bar">
            <ProgressBar
              current={barLength2 > '2' ? 2 : barLength2}
              total={3}
              text={isMint2}
              isAir={isAir}
              onDataHandle={recommendationMint}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="btn" onClick={recommendationBuy}>
              {buy2}
            </div>
          </div>
        </div>
      </div> */}
    </Instr>
  )
}
export default App
