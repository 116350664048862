import React from "react";
import { SvgProps } from "../types";

const Logo: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <div style={{ width: 131, height: 48 }}>
      <img style={{ width: "100%", height: "100%" }} src="https://upload.ggg.dog/1725422723/1.png" alt="" />
    </div>
  );
};

export default Logo;
