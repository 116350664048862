import { styled } from "styled-components";
import { darkColors } from "../../theme/colors";
import { Box, Flex } from "../Box";
import SocialLinks from "./Components/SocialLinks";

export const StyledFooter = styled(Flex)`
  background: rgba(17, 4, 43, 1);
  
`;
export const Transaction = styled(Flex)`
  width:90px;
  line-height:32px;
  justify-content:center;
  margin-top:16px;
  color:rgb(255, 169, 63);
  border:1px solid;
  border-radius: 10px;
`;

export const StyledList = styled.ul`
  list-style: none;
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.lg} {
    margin-bottom: 0px;
  }
`;

export const StyledListItem = styled.li`
  font-size: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;

  &:first-child {
    color: ${darkColors.secondary};
    font-weight: 600;
    text-transform: uppercase;
  }
`;

export const StyledIconMobileContainer = styled(Box)`
  margin-bottom: 24px;
`;

export const StyledToolsContainer = styled(Flex)`
  // border-top: ${darkColors.cardBorder};
   border-top: 1px solid rgba(44, 36, 76, 1);
  padding: 24px 0;
   line-height: 150%;
  margin-bottom: 24px;

  ${({ theme }) => theme.mediaQueries.sm} {
    border-top-width: 0;
    border-bottom-width: 0;
    padding: 0 0;
    margin-bottom: 0;
  }
`;

export const StyledSocialLinks = styled(SocialLinks)`
  border-bottom: transparent;
`;
export const Instruction = styled.div`
  line-height: 15px;
  font-family: Outfit;
  font-size: 12px;
  p{
    color: var(--灰色, rgba(111, 99, 158, 1));
    font-weight: 700;
  }
  span{
    color: var(--灰色, rgba(111, 99, 158, 1));
  }
`;


export const StyledText = styled.span`
  color: ${darkColors.text};
`;
