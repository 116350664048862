import styled from 'styled-components'

export const Features = styled.div`
  padding: 0 16px;
  margin-top: 43px;
  margin-bottom: 25px;
`
export const Content = styled.div`
  border: 1px solid var(--描边, rgba(231, 227, 235, 1));
  box-sizing: border-box;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(20px);
  border-radius: 40px;
  padding: 32px 24px;
  .title {
    text-align: center;
    color: var(--黑色, rgba(40, 13, 95, 1));
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
    .feature {
      color: var(--紫色, rgba(118, 69, 217, 1));
      font-family: 'PingFang SC';
      font-weight: 600;
      margin-left: 5px;
    }
  }
  .content {
    font-family: 'PingFang SC';
    p {
      margin-top: 12px;
      color: var(--黑色, rgba(40, 13, 95, 1));
      font-weight: 600;
      font-size: 15px;
      line-height: 150%;
    }
    .instruction {
      margin-top: 12px;
      color: var(--灰色, rgba(111, 99, 158, 1));
      font-weight: 400;
      font-size: 13px;
      line-height: 150%;
    }
    > div {
      margin-top: 24px;
    }
  }
`
