import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22.5536 5.46443C21.9554 6.33943 21.2322 7.08496 20.3839 7.70103C20.3929 7.82603 20.3973 8.01353 20.3973 8.26353C20.3973 9.42425 20.2277 10.5827 19.8884 11.739C19.5491 12.8952 19.0335 14.0046 18.3415 15.0671C17.6496 16.1296 16.8259 17.0693 15.8706 17.8863C14.9152 18.7033 13.7634 19.355 12.4152 19.8417C11.067 20.3283 9.62501 20.5716 8.0893 20.5716C5.66966 20.5716 3.45537 19.9242 1.44644 18.6296C1.75894 18.6653 2.10716 18.6832 2.49108 18.6832C4.50001 18.6832 6.29019 18.0671 7.86162 16.835C6.92412 16.8171 6.08483 16.5292 5.34376 15.9711C4.60269 15.4131 4.09376 14.701 3.81698 13.835C4.11162 13.8796 4.38394 13.9019 4.63394 13.9019C5.01787 13.9019 5.39734 13.8528 5.77234 13.7546C4.77234 13.5492 3.94421 13.0515 3.28796 12.2613C2.63171 11.4711 2.30358 10.5537 2.30358 9.50907V9.4555C2.91073 9.79478 3.56251 9.97782 4.25894 10.0046C3.66966 9.61175 3.20091 9.09835 2.85269 8.46443C2.50448 7.8305 2.33037 7.143 2.33037 6.40193C2.33037 5.61621 2.5268 4.88853 2.91966 4.21889C4.00001 5.54925 5.31475 6.61398 6.86385 7.41309C8.41296 8.21219 10.0714 8.65639 11.8393 8.74568C11.7679 8.40639 11.7322 8.07603 11.7322 7.7546C11.7322 6.55818 12.154 5.53809 12.9978 4.69434C13.8415 3.85059 14.8616 3.42871 16.0581 3.42871C17.3081 3.42871 18.3616 3.88407 19.2188 4.79478C20.192 4.60728 21.1072 4.25907 21.9643 3.75014C21.6339 4.77693 21 5.57157 20.0625 6.13407C20.8929 6.04478 21.7232 5.82157 22.5536 5.46443Z" fill="#6F639E" />
    </svg>

  );
};

export default Icon;
